import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"

import DaveMap from "../components/daves/map"
import Stickers from "../components/daves/stickers"

const DavesPage = () => {
  const data = useStaticQuery(graphql`
    query Daves {
      site {
        siteMetadata {
          siteUrl
        }
      }
      craft {
        daves: entries(title: "Daves") {
          dateCreated
          dateUpdated
          ... on Craft_daves_daves_Entry {
            image: seoImage {
              title
              url(width: 720, height: 475, quality: 100, immediately: true)
            }
            imageFile {
              childImageSharp {
                fixed(width: 600, quality: 90) {
                  src
                }
              }
            }
            seoTitle
            seoDescription
            introduction
          }
        }
      }
    }
  `)

  const seoTitle = data.craft.daves[0].seoTitle
  const seoDescription = data.craft.daves[0].seoDescription
  const seoImage =
    data.site.siteMetadata.siteUrl +
    data.craft.daves[0].imageFile[0].childImageSharp.fixed.src

  const DavesContainer = styled.div`
    padding-top: 6.5rem;
  `

  const DavesIntro = styled.section`
    max-width: 58rem;
  `

  const DavesContent = styled.div`
    section {
      &:last-of-type {
        margin-bottom: 4rem;
      }
    }
  `

  const DavesTitle = styled.h1`
    margin-bottom: 0;
    font-size: 2.75rem;

    &:after {
      content: "";
      margin: 4rem 0 2rem;
      width: 3rem;
      height: 0.36rem;
      background-color: ${props => props.theme.primaryYellow};
      display: block;
      position: relative;
      left: -1.5rem;
    }
  `

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        pathname={`daves`}
        website
      />
      <DavesContainer>
        <DavesIntro>
          <DavesTitle>Daves</DavesTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: data.craft.daves[0].introduction,
            }}
          />
        </DavesIntro>
        <DavesContent>
          <DaveMap />
          <Stickers />
        </DavesContent>
      </DavesContainer>
    </Layout>
  )
}

export default DavesPage
