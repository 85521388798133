import React, { Component } from "react"
import styled from "@emotion/styled"
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet"

class DaveMap extends Component {
  render() {
    const MapContainer = styled.section`
      max-width: 98rem;
      position: relative;
      z-index: 0;

      .leaflet-container {
        height: 40vh;
        width: 100%;

        @media (min-width: 46rem) {
          height: 70vh;
        }
      }
    `

    const getLatLngBounds = () => {
      return [
        [52.39, 13.05], // Germany
        [51.5, -0.09], // London
        [51.4, -2.59], // Bristol
        [43.615, -116.2023], // Boise, Idaho
        [51.778981, -0.31942], // St Albans
        [50.827231, -0.061952], // Rottingdean
        [40.879481, -73.906156], // New York
        [30.429863, -97.719663], // Austin
        [53.567699, -2.340112], // Bolton
        [53.711441, -2.630374], // Buckshaw Village
        [40.169797, -105.226208], // Boulder
        [57.717084, -2.874062], // Buckie
        [37.455575, -122.079415], // Los Altos
        [37.506436, -5.832162], // Seville
      ]
    }

    return (
      <MapContainer>
        {typeof window !== "undefined" && (
          <LeafletMap
            center={[50, 10]}
            zoom={2}
            maxZoom={7}
            attributionControl={false}
            zoomControl={true}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            dragging={true}
            animate={true}
            easeLinearity={0.55}
            bounds={getLatLngBounds()}
          >
            <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png" />
            <Marker position={[52.39, 13.05]}>
              <Popup>Potsdam, Germany</Popup>
            </Marker>
            <Marker position={[51.5, -0.09]}>
              <Popup>London, England</Popup>
            </Marker>
            <Marker position={[51.4, -2.59]}>
              <Popup>Bristol, England</Popup>
            </Marker>
            <Marker position={[43.615, -116.2023]}>
              <Popup>Boise, Idaho</Popup>
            </Marker>
            <Marker position={[51.778981, -0.31942]}>
              <Popup>St Albans, England</Popup>
            </Marker>
            <Marker position={[50.827231, -0.061952]}>
              <Popup>Rottingdean, England</Popup>
            </Marker>
            <Marker position={[40.879481, -73.906156]}>
              <Popup>New York, New York</Popup>
            </Marker>
            <Marker position={[30.429863, -97.719663]}>
              <Popup>Austin, Texas</Popup>
            </Marker>
            <Marker position={[53.567699, -2.340112]}>
              <Popup>Bolton, England</Popup>
            </Marker>
            <Marker position={[53.711441, -2.630374]}>
              <Popup>Buckshaw Village, England</Popup>
            </Marker>
            <Marker position={[40.169797, -105.226208]}>
              <Popup>Boulder, Colorado</Popup>
            </Marker>
            <Marker position={[57.717084, -2.874062]}>
              <Popup>Buckie, Scotland</Popup>
            </Marker>
            <Marker position={[37.455575, -122.079415]}>
              <Popup>Los Altos, California</Popup>
            </Marker>
            <Marker position={[37.506436, -5.832162]}>
              <Popup>Seville, Spain</Popup>
            </Marker>
          </LeafletMap>
        )}
      </MapContainer>
    )
  }
}

export default DaveMap
