import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const Stickers = () => {
  const data = useStaticQuery(graphql`
    query DaveStickers {
      craft {
        daves: entries(title: "Daves") {
          ... on Craft_daves_daves_Entry {
            image: daveStickers {
              title
              url(width: 800, height: 800, quality: 100, immediately: true)
            }
            imageFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  const stickers = data.craft.daves[0].imageFile

  // console.log(stickers)

  const StickerContainer = styled.section`
    max-width: 78rem;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin-bottom: 4rem;
    padding-top: 5rem;
  `

  const DaveSticker = styled.div`
    grid-column-end: span 1;
  `

  return (
    <StickerContainer>
      {stickers.map((sticker, i) => (
        <DaveSticker key={i}>
          <Img
            fluid={sticker.childImageSharp.fluid}
            alt="Sticker"
            title="Sticker"
          />
        </DaveSticker>
      ))}
    </StickerContainer>
  )
}

export default Stickers
